import React from "react"
import SEO from "../components/seo"
import "./awardees.css"
import logo from "../images/logo.png"

const CompanyAwardees = () => {
	const awardees = [
		"Fopefoluwa Ajayi",
		"Katelyn Entenmann",
		"Dylan Moo Hernandez",
		"Fiyinfoluwa Ajayi",
		"Grace Farrell",
		"Jorge Luis Moreno Melo",
		"Aden Anderson",
		"Mason Galler",
		"Emma Mulligan",
		"Grace Baker",
		"Moriah Geiser",
		"Thomas Nagy",
		"Ethan Batchelder",
		"Aliya Gerber",
		"Jesse Oloski",
		"Brooke Bayless",
		"Brady Hager",
		"Chloe Putz",
		"Zachary Bringle",
		"Adeline Hall",
		"Mackenzie Ragle",
		"Braeden Caropresso",
		"Abigail Jason",
		"Lindsey Repp",
		"Elliana Cornils",
		"Maya Jha",
		"Samantha Slonecker",
		"Emerson Cotter",
		"Courtney Kaufman",
		"Brock Sproule",
		"Ella Coxhead",
		"Kailey Keskitalo",
		"Kimberly Turner",
		"Alexander Dahl",
		"Benjamin Kopper",
		"Caleb Ulman",
		"Justin Dahl",
		"Jackson Mckinney",
		"Steven Wedemeyer",
		"Wyatt Draves",
		"Mahaley Metcalf"
	];

	return (
		<div className="awardees">
			<img className="logo" src={logo} />
			<h2 className="awardee-header">We are pleased to announce the 2023 Brunswick Company Sons &amp; Daughters Scholarship Program Awardees</h2>
			<div className="awardee-grid">
				{awardees.map((awardee) => <span>{awardee}</span>)}
			</div>
		</div>
	);
};

export default CompanyAwardees
